import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const CookiePolicy = loadable(() => import(/* webpackChunkName: "CookiePolicy" */ '../containers/CookiePolicy/CookiePolicy'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const DashboardPage = loadable(() => import(/* webpackChunkName: "DashboardPage" */ '../containers/DashboardPage/DashboardPage'));
const DoctorsPaymentPage = loadable(() => import(/* webpackChunkName: "DoctorsPaymentPage" */ '../containers/DoctorsPaymentPage/DoctorsPaymentPage'));
const DoctorOnboardingPage = loadable(() => import(/* webpackChunkName: "DoctorOnboardingPage" */ '../containers/DoctorOnboardingPage/DoctorOnboardingPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPatientPage = loadable(() => import(/* webpackChunkName: "LandingPatientPage" */ '../containers/LandingPatientPage/LandingPatientPage'));
const SearchContentPage = loadable(() => import(/* webpackChunkName: "SearchContentPage" */ '../containers/SearchContentPage/SearchContentPage'));
const MenopauseResourcePage = loadable(() => import(/* webpackChunkName: "MenopauseResourcePage" */ '../containers/MenopauseResourcePage/MenopauseResourcePage'));
const Homepage = loadable(() => import(/* webpackChunkName: "Homepage" */ '../containers/Homepage/Homepage'));
const UpcommingEventPage = loadable(() => import(/* webpackChunkName: "UpcommingEventPage" */ '../containers/UpcommingEventPage/UpcommingEventPage'));

const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const MenopausePage = loadable(() => import(/* webpackChunkName: "MenopausePage" */ '../containers/MenopausePage/MenopausePage'));
const OurMenopauseJourneyPage = loadable(() => import(/* webpackChunkName: "OurMenopauseJourneyPage" */ '../containers/OurMenopauseJourneyPage/OurMenopauseJourneyPage'));
const MenopauseLandingPage = loadable(() => import(/* webpackChunkName: "MenopauseLandingPage" */ '../containers/MenopauseLandingPage/MenopauseLandingPage'));
const SexLandingPage = loadable(() => import(/* webpackChunkName: "SexLandingPage" */ '../containers/SexLandingPage/SexLandingPage'));
const DoctorsPage = loadable(() => import(/* webpackChunkName: "DoctorsPage" */ '../containers/DoctorsPage/DoctorsPage'));
const OnboardingSuccessPage = loadable(() => import(/* webpackChunkName: "OnboardingSuccessPage" */ '../containers/OnboardingSuccessPage/OnboardingSuccessPage'));
const CompaniesPage = loadable(() => import(/* webpackChunkName: "CompaniesPage" */ '../containers/CompaniesPage/CompaniesPage'));
const GoogleAuthPage = loadable(() => import(/* webpackChunkName: "GoogleAuthPage" */ '../containers/GoogleAuthPage/GoogleAuthPage'));
const BlogPage = loadable(() => import(/* webpackChunkName: "BlogPage" */ '../containers/BlogPage/BlogPage'));
const FAQPage = loadable(() => import(/* webpackChunkName: "FAQPage" */ '../containers/FAQPage/FAQPage'));
const SingleBlogPage = loadable(() => import(/* webpackChunkName: "SingleBlogPage" */ '../containers/SingleBlogPage/SingleBlogPage'));
const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage'));
const VideoGalleryPage = loadable(() => import(/* webpackChunkName: "VideoGalleryPage" */ '../containers/VideoGalleryPage/VideoGalleryPage'));
const FamilyProgramPage = loadable(() => import(/* webpackChunkName: "FamilyProgramPage" */ '../containers/FamilyProgramPage/FamilyProgramPage'));
const MyInvoicesPage = loadable(() => import(/* webpackChunkName: "MyInvoicesPage" */ '../containers/MyInvoicesPage/MyInvoicesPage'));
const MeetingPage = loadable(() => import(/* webpackChunkName: "MeetingPage" */ '../containers/MeetingPage/MeetingPage'));
const ContactPage = loadable(() => import(/* webpackChunkName: "ContactPage" */ '../containers/ContactPage/ContactPage'));
const ListingPageCoverPhoto = loadable(() => import(/* webpackChunkName: "ListingPageCoverPhoto" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCoverPhoto'));
const ListingPageCarousel = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithGrid = loadable(() => import(/* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithGrid'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const VouchersPage = loadable(() => import(/* webpackChunkName: "VouchersPage" */ '../containers/VouchersPage/VouchersPage'));
const TopicDetailsPage = loadable(() => import(/* webpackChunkName: "TopicDetailsPage" */ '../containers/TopicDetailsPage/TopicDetailsPage'));
const EventsPage = loadable(() => import(/* webpackChunkName: "EventsPage" */ '../containers/EventsPage/EventsPage'));
const EventDetailPage = loadable(() => import(/* webpackChunkName: "EventDetailPage" */ '../containers/EventDetailPage/EventDetailPage'));
const SubscriptionPage = loadable(() => import(/* webpackChunkName: "SubscriptionPage" */ '../containers/SubscriptionPage/SubscriptionPage'));
const CoursesPage = loadable(() => import(/* webpackChunkName: "CoursesPage" */ '../containers/CoursesPage/CoursesPage'));
const GuidesPage = loadable(() => import(/* webpackChunkName: "GuidesPage" */ '../containers/GuidesPage/GuidesPage'));
const AIChatPage = loadable(() => import(/* webpackChunkName: "AIChatPage" */ '../containers/AIChatPage/AIChatPage'));
const SymptomPage = loadable(() => import(/* webpackChunkName: "SymptomPage" */ '../containers/SymptomPage/SymptomPage'));
const TheBasicsDetailsPage = loadable(() => import(/* webpackChunkName: "TheBasicsDetailsPage" */ '../containers/TheBasicsDetailsPage/TheBasicsDetailsPage'));
const InvoicesPage = loadable(() => import(/* webpackChunkName: "InvoicesPage" */ '../containers/InvoicesPage/InvoicesPage'));
const CustomerOnboardingPage = loadable(() => import(/* webpackChunkName: "CustomerOnboardingPage" */ '../containers/CustomerOnboardingPage/CustomerOnboardingPage'));


// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));

export const ACCOUNT_SETTINGS_PAGES = [

  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
export const draftId = '00000000-0000-0000-0000-000000000000';
export const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (layoutConfig) => {
  const SearchPage = layoutConfig?.searchPage?.variantType === 'map'
    ? SearchPageWithMap
    : SearchPageWithGrid;
  const ListingPage = layoutConfig?.listingPage?.variantType === 'carousel'
    ? ListingPageCarousel
    : ListingPageCoverPhoto;

  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
    },
    {
      path: '/en',
      name: 'LandingPage',
      component: LandingPage,
    },
    {
      path: '/menopause',
      name: 'MenopausePage',
      component: MenopausePage,
      loadData: pageDataLoadingAPI.MenopausePage.loadData,
    },
    {
      path: '/menopause/en',
      name: 'MenopausePage',
      component: MenopausePage,
      loadData: pageDataLoadingAPI.MenopausePage.loadData,
    },
    {
      path: '/home/sexual-wellness',
      name: 'SexLandingPage',
      component: SexLandingPage,
    },
    {
      path: '/get-care',
      name: 'LandingPatientPage',
      component: LandingPatientPage,
    },
    {
      path: '/search-content',
      name: 'SearchContentPage',
      auth: true,
      component: SearchContentPage,
    },
    {
      path: '/resources/menopause',
      auth: true,
      name: 'MenopauseResourcePage',
      component: MenopauseResourcePage,
    },
    {
      path: '/home',
      auth: true,
      name: 'Homepage',
      component: Homepage,
    },
    {
      path: '/symptom/:symptom',
      auth: true,
      name: 'SymptomPage',
      component: SymptomPage,
    },
    {
      path: '/the-basics/:slug',
      auth: true,
      name: 'TheBasicsDetailsPage',
      component: TheBasicsDetailsPage,
    },
    {
      path: '/invoice',
      auth: true,
      name: 'InvoicesPage',
      component: InvoicesPage,
    },

    {
      path: '/upcomming-events',
      // auth: true,
      name: 'UpcommingEventPage',
      component: UpcommingEventPage,
      // loadData: pageDataLoadingAPI.UpcommingEventPage.loadData,
    },

    {
      path: '/doctor/onboarding',
      auth: true,
      name: 'DoctorOnboardingPage',
      component: DoctorOnboardingPage,
      // loadData: pageDataLoadingAPI.LandingPatientPage.loadData,
    },
    {
      path: '/doctor/onboarding/success',
      auth: true,
      name: 'OnboardingSuccessPage',
      component: OnboardingSuccessPage,
    },
    {
      path: '/doctors',
      name: 'DoctorsPage',
      component: DoctorsPage,
      // loadData: pageDataLoadingAPI.DoctorsPage.loadData,
    },
    {
      path: '/rea',
      name: 'DoctorsPage',
      component: DoctorsPage,
      // loadData: pageDataLoadingAPI.DoctorsPage.loadData,
    },
    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      component: DashboardPage,
      loadData: pageDataLoadingAPI.DashboardPage.loadData,
    },
    {
      path: '/vouchers',
      name: 'VouchersPage',
      auth: true,
      component: VouchersPage,
      loadData: pageDataLoadingAPI.VouchersPage.loadData,
    },
    {
      path: '/doctors-payments',
      name: 'DoctorsPaymentPage',
      auth: true,
      component: DoctorsPaymentPage,
      loadData: pageDataLoadingAPI.DoctorsPaymentPage.loadData,
    },
    {
      path: '/companies',
      name: 'CompaniesPage',
      component: CompaniesPage,
      // loadData: pageDataLoadingAPI.CompaniesPage.loadData,
    },
    {
      path: '/blogs',
      name: 'BlogPage',
      component: BlogPage,
      loadData: pageDataLoadingAPI.BlogPage.loadData,
    },
    {
      path: '/en/blogs',
      name: 'BlogPage',
      component: BlogPage,
      loadData: pageDataLoadingAPI.BlogPage.loadData,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
      // loadData: pageDataLoadingAPI.FAQPage.loadData,
    },
    {
      path: '/google-auth',
      name: 'GoogleAuthPage',
      component: GoogleAuthPage,
      loadData: pageDataLoadingAPI.GoogleAuthPage.loadData,
    },
    {
      path: '/blog/:id/:slug',
      name: 'SingleBlogPage',
      component: SingleBlogPage,
      loadData: pageDataLoadingAPI.SingleBlogPage.loadData,
    },
    {
      path: '/en/blog/:id/:slug',
      name: 'SingleBlogPage',
      component: SingleBlogPage,
      loadData: pageDataLoadingAPI.SingleBlogPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
      // loadData: pageDataLoadingAPI.AboutPage.loadData,
    },
    {
      path: '/gallery',
      name: 'VideoGalleryPage',
      component: VideoGalleryPage,
      // loadData: pageDataLoadingAPI.AboutPage.loadData,
    },
    {
      path: '/meeting/:id',
      name: 'MeetingPage',
      auth: true,
      component: MeetingPage,
      loadData: pageDataLoadingAPI.MeetingPage.loadData,
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: ContactPage,
      // loadData: pageDataLoadingAPI.ContactPage.loadData,
    },
    {
      path: '/p/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/search',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/signup/:type',
      name: 'AuthenticationPageSelector',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }, ...rest),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id/chat',
      name: 'SaleChatPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/order/:id/chat',
      name: 'OrderChatPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }, ...rest),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/cookie-policy',
      name: 'CookiePolicy',
      component: CookiePolicy,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
    {
      path: '/written-guide/:slug/:guide',
      auth: true,
      name: 'TopicDetailsPage',
      component: TopicDetailsPage,
    },
    {
      path: '/written-guide/:slug',
      name: 'WrittenGuidesPage',
      auth: true,
      component: TopicDetailsPage,
    },
    {
      path: '/events',
      name: 'EventsPage',
      auth: true,
      component: EventsPage,
    },
    {
      path: '/event/:title',
      name: 'EventDetailPage',
      auth: true,
      component: EventDetailPage,
    },
    {
      path: '/subscription',
      name: 'SubscriptionPage',
      auth: true,
      component: SubscriptionPage,
      loadData: pageDataLoadingAPI.SubscriptionPage.loadData,

    },
    {
      path: '/courses',
      name: 'CoursesPage',
      component: CoursesPage,
    },
    {
      path: '/resources/family-program',
      name: 'FamilyProgramPage',
      component: FamilyProgramPage,
    },
    {
      path: '/guides/:module',
      name: 'GuidesBasePage',
      auth: true,
      component: GuidesPage,
    },
    {
      path: '/guides/:module/:slug',
      name: 'GuidesPage',
      auth: true,
      component: GuidesPage,
    },
    {
      path: '/ai-chat',
      name: 'AIChatPage',
      // auth: true,
      component: AIChatPage,
      loadData: pageDataLoadingAPI.AIChatPage.loadData,

    },
    {
      path: '/chat/:id',
      name: 'NewAIChatPage',
      component: AIChatPage,
      loadData: pageDataLoadingAPI.AIChatPage.loadData,

    },
    {
      path: '/my-invoices',
      auth: true,
      name: 'MyInvoicesPage',
      component: MyInvoicesPage,
    },
    {
      path: '/customer-onboarding',
      name: 'CustomerOnboardingPage',
      component: CustomerOnboardingPage,
    },

  ];
};

export default routeConfiguration;
